import { React, useContext, useEffect, useRef, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";

import { FaDownload } from "react-icons/fa";

import { AuthWizardContext, ExportContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import DownloadCenterBacktoTopButton from "./DownloadCenterBacktoTopButton";
import DownloadLineItem from "./DownloadLineItem";
import DownloadCenterClearAllResultsButton from "./DownloadCenterClearAllResultsButton";
import DownloadCenterRecentDownloads from "./DownloadCenterRecentDownloads";

import "./DownloadCenter.scss";

const DownloadCenter = ({ iconColor }) => {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const { exportArray, dispatch } = useContext(ExportContext);
  const [downloadItems, setDonwloadItems] = useState([]);
  const [downloadApiItems, setDonwloadApiItems] = useState([]);
  const [notify, setNotify] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showBacktoTop, setShowBacktoTop] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadMore, setloadMore] = useState(false);
  const element = useRef(null);

  const handleShow = () => {
    setShow(!show);
  };

  const handleBacktoTop = () => {
    if (element && element.current) {
      element.current.scrollTop = 0;
    }
  };

  const handleWindowScroll = (e) => {
    if (e.target.scrollTop > 100 && !showBacktoTop) {
      setShowBacktoTop(true);
    } else if (e.target.scrollTop < 101 && showBacktoTop) {
      setShowBacktoTop(false);
    }
  };

  const getApiDownloadList = async (page = 1) => {
    setIsLoading(true);
    setCurrentPage(page);
    try {
      let data = await apiCall({
        url: "user/export-logs",
        params: { page: page },
      });
      if (data && typeof data === "object") {
        data.data && setDonwloadApiItems((prev) => [...prev, ...data.data]);
        let moreStatus =
          data.meta && data.meta.last_page && page < data.meta.last_page
            ? true
            : false;

        setloadMore(moreStatus);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setNotify(false);
  }, [show]);

  useEffect(() => {
    if (currentPage < 1 || (show && loadMore && !isLoading)) {
      getApiDownloadList(currentPage + 1);
    }
  }, [show, loadMore, isLoading, currentPage]);

  useEffect(() => {
    setDonwloadItems(exportArray.filter((item) => item.deleted !== true));
    return () => {
      setDonwloadItems([]);
    };
  }, [exportArray]);

  useEffect(() => {
    // listen to export notification socket
    if (user.id) {
      const currentURL = window.location.href;
      const urlObject = new URL(currentURL);
      const domain = urlObject.hostname;
      window.Echo.channel(`user_notification_${domain}_${user.id}`).listen(
        "ExportLogNotificationBroadcast",
        (exportData) => {
          if (
            typeof exportData === "object" &&
            typeof exportData.export_log === "object"
          ) {
            if (!show) {
              let timeOut = 0;
              if (setNotify) {
                setNotify(false);
                timeOut = 500;
              }

              setTimeout(() => {
                setNotify(true);
              }, timeOut);
            }
            // update export context item
            let exportLogs = exportData.export_log;
            exportLogs.dbId = exportLogs.id;
            delete exportLogs.id;

            if (exportLogs?.status === "COMPLETED") {
              setTimeout(() => {
                dispatch({
                  type: "UPDATE-STATUS-BY-FILE-URL",
                  payload: {
                    ...exportLogs,
                  },
                });
              }, 5000);
            } else {
              dispatch({
                type: "UPDATE-STATUS-BY-FILE-URL",
                payload: {
                  ...exportLogs,
                },
              });
            }
          }
        }
      );

      return () => {
        setCurrentPage(1);
        setDonwloadApiItems([]);
        window.Echo.channel(
          `user_notification_${domain}_${user.id}`
        ).stopListening("ExportLogNotificationBroadcast");
        clearTimeout();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  return (
    <div className="position-relative">
      <Button
        variant="transparent"
        onClick={handleShow}
        className={`px-1 py-0 nav-link download-center-button download-center-button-driver-element${
          notify ? " download-center-button-notify" : ""
        }`}
        style={{ boxShadow: "none" }}
      >
        <FaDownload
          title={Translate("Download Center")}
          size={20}
          color={iconColor}
        />
      </Button>

      <DownloadCenterRecentDownloads
        show={notify}
        items={downloadItems}
        handleShowAll={handleShow}
      />

      <Offcanvas show={show} onHide={handleShow} placement="end">
        <Offcanvas.Header closeButton className="border-bottom">
          <Offcanvas.Title className="fw-bold mb-0 d-flex flex-row align-items-center">
            <FaDownload className="me-2" style={{ color: "#c1c1c1" }} />
            <span>{Translate("Download Center")}</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        {showBacktoTop ? (
          <DownloadCenterBacktoTopButton onClick={handleBacktoTop} />
        ) : null}
        <Offcanvas.Body
          className="p-0 d-flex flex-column"
          onScroll={handleWindowScroll}
          ref={element}
        >
          {!isLoading &&
          downloadItems.length < 1 &&
          downloadApiItems.length < 1 ? (
            <h5 className="text-center text-secondary my-auto py-3">
              {Translate("Nothing found!")} &#128578;
            </h5>
          ) : (
            <>
              <DownloadCenterClearAllResultsButton
                exportArrayDispatch={dispatch}
                setDonwloadApiItems={setDonwloadApiItems}
                setDonwloadItems={setDonwloadItems}
              />
              {downloadItems.map((item, index) => (
                <DownloadLineItem
                  key={index}
                  item={item}
                  dispatch={dispatch}
                  className={
                    isLoading &&
                    downloadApiItems.length < 1 &&
                    downloadItems.length === index + 1
                      ? " mb-3"
                      : ""
                  }
                />
              ))}
            </>
          )}

          {downloadItems.length > 0 && downloadApiItems.length > 0 ? (
            <div className="py-1 text-center w-100 text-uppercase border-bottom">
              <small className="fw-bold">{Translate("Old files")}</small>
            </div>
          ) : null}

          {downloadApiItems.map((item, index) => (
            <DownloadLineItem
              key={index}
              item={item}
              dispatch={setDonwloadApiItems}
              className={
                isLoading && downloadApiItems.length === index + 1
                  ? " mb-3"
                  : ""
              }
              old={true}
            />
          ))}

          {isLoading ? (
            <LoadingScreen message="Looking for items" />
          ) : !loadMore && downloadApiItems.length > 0 ? (
            <h5 className="text-center text-secondary mt-auto py-3">
              {Translate("End reached!")} &#128578;
            </h5>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default DownloadCenter;
