import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  // useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Col, Offcanvas, Row, Form, Button } from "react-bootstrap";
import { BiTask } from "react-icons/bi";
import { apiCall } from "helpers/apiCalls";
import { SaveButton, SaveNewButton } from "module/common/Buttons/CommonButton";
import { tasksFormKeys } from "helpers/formKeys";
import { showToast } from "module/common/Toast/toast";
import SelectUsers from "components/form/SelectUsers";
import MutiSelectTaskLabel from "../../../components/form/MutiSelectTaskLabel";
import removeRefData from "helpers/removeRefData";
import SelectTaskStatus from "components/form/SelectTaskStatus";
import SelectProject from "components/form/SelectProject";
import ListLoading from "module/common/ListLoading";
import MultiSelectUsers from "components/form/MultiSelectUsers";
import TextEditor from "module/common/TextEditor/TextEditor";
import removeEmptyFields from "helpers/removeEmptyFields";
import AppTimePicker from "components/app-time-picker/AppTimePicker";
import SelectSprint from "components/form/SelectSprint";
import useAxisproPermission from "hooks/useAxisproPermission";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import { StoreContext } from "context/Context";
import { BsStars } from "react-icons/bs";
import CommonAiGeneratorForm from "module/common/Forms/CommonAiGeneratorForm";
import SelectProduct from "components/form/SelectProduct";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import SelectPriority from "components/form/SelectPriority";

function TaskForm({ show = true, handleCloseForm, openFrom }) {
  const Translate = useAxisproTranslate();
  let navigate = useNavigate();
  const [formData, setFormData] = useState(tasksFormKeys);
  const [queryParams] = useSearchParams();
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  let { itemId, itemInfoId, overviewId, client_id, empId } = useParams();
  const [dataProcessing, setDataProcessing] = useState(false);
  const pageNumber = queryParams.get("page");
  // const tab = queryParams.get("tab");
  const statusFilter = queryParams.get("status_filter");
  const location = useLocation();
  // const [getData] = useOutletContext() || [{}];
  const renderFrom = location.pathname.split("/")[1];
  const allQueryParams = Object.fromEntries([...queryParams]);
  const commonSearchParams = {
    ...allQueryParams,
    tab: "tasklist",
    status_filter: statusFilter ?? "",
    page: itemId ? pageNumber : 1,
  };
  const projectId = queryParams.get("project");
  const [onSaveNew, setOnSaveNew] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showGenerativeAi, setShowGenerativeAi] = useState(false);
  const axisProPermission = useAxisproPermission();
  const [
    updateTaskStatusPermission,
    updateTaskLabelPermission,
    updateTaskAssigneePermission,
  ] = ["update-task-status", "update-task-label", "update-task-assignee"].map(
    (permission) => axisProPermission(permission)
  );
  const isClone = location.pathname.includes("clone");
  const withoutProject = renderFrom === "project" || renderFrom === "sprints";
  const { store, addIitemToStore } = useContext(StoreContext);
  const triggerFromQuickLinkTask = store?.triggerFromQuickLinkTask ?? false;
  const actualRoute = localStorage.getItem("actualRoute");

  useEffect(() => {
    if (!openFrom && itemId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const getDataItem = async () => {
    setDataProcessing(true);
    const data = await apiCall({
      url: `crm/task/${itemId}`,
    });
    const labelArray = data.labels.map((item) => ({
      label: item.label,
      value: item.id,
    }));

    const collabArray = data.collaborators.map((item) => ({
      label: item.user.name,
      value: item.user.id,
    }));

    setFormData({
      ...formData,
      project_id: data.project_id,
      project_id_ref: {
        label: data.project_title,
        value: data.project_id,
      },
      title: data.title,
      description: data.description,
      start_date: data.start_date,
      end_date: data.end_date,
      assigned_to: data.assigned_to,
      assigned_to_ref: {
        label: data.assignee
          ? data.assignee.first_name + " " + data.assignee.last_name
          : "",
        value: data.assignee ? data.assigned_to : "",
      },
      coordinator: data?.coordinator_id ? data?.coordinator_id : "",
      coordinator_ref: {
        value: data?.coordinator_id,
        label: data?.coordinator_name,
      },
      status: data.status,
      status_ref: { label: data.status.replace("_", " "), value: data.status },
      labels: labelArray.map((item) => ({ id: item.value })),
      labels_ref: labelArray,
      collaborators: collabArray.map((item) => ({ id: item.value })),
      collaborators_ref: collabArray,
      priority: data.priority,
      priority_ref: {
        label: data.priority,
        value: data.priority,
      },
      sprint_id: data?.sprint_id ?? "",
      sprint_id_ref: data?.sprint_id
        ? {
            label: data?.sprint_name,
            value: data?.sprint_id,
          }
        : "",
      product_id: data?.product_id,
      product_id_ref: {
        label: data.product_name,
        value: data.product_id,
      },
    });
    setDataProcessing(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      const editor = document.querySelector(".ql-editor");
      if (editor) {
        const images = editor.querySelectorAll("img");
        images.forEach((image) => {
          image.style.width = "150px";
          image.style.height = "auto";
        });
      }

      if (
        action.action === "select-option" ||
        action.action === "remove-value"
      ) {
        if (action.name === "labels" || action.name === "collaborators") {
          const labelArray = e.map((item) => ({ id: item.value }));
          value = labelArray;
        } else {
          value = e.code ? e.code : e.value;
        }
      } else if (action.action === "clear") {
        action.name === "project_id" ||
        action.name === "sprint_id" ||
        action.name === "assigned_to" ||
        action.name === "product_id"
          ? (value = "")
          : (value = []);
      } else if (action.action === "text-editor") {
        value = action.value;
      }
      if (action.name === "project_id") {
        newFormData = {
          ...formData,
          [action.name]: value,
          [action.name + "_ref"]: e,
          product_id: "",
          product_id_ref: {
            label: "",
            value: "",
          },
        };
      } else {
        newFormData = {
          ...formData,
          [action.name]: value,
          [action.name + "_ref"]: e,
        };
      }
    }
    setFormData(newFormData);
  };

  const handleNavigation = (saveAndNew) => {
    let navigatePath = "";

    if (openFrom === "quick_link" && saveAndNew) {
      return null;
    } else if (openFrom === "quick_link" && !saveAndNew) {
      handleCloseForm();
      return;
    }

    if (actualRoute && actualRoute.includes("employee/overview")) {
      navigate(actualRoute);
    } else {
      if (renderFrom === "project") {
        navigatePath = `/project/info/${itemInfoId}${
          saveAndNew ? "/task/add" : ""
        }`;
      } else if (renderFrom === "sprints") {
        navigatePath = `/sprints/info/${itemInfoId}${
          saveAndNew ? "/task/add" : ""
        }`;
      } else if (renderFrom === "account") {
        navigatePath = `/account/overview/${client_id}${
          saveAndNew ? "/task/add" : ""
        }`;
      } else if (renderFrom === "employee") {
        navigatePath = `/employee/overview/${empId}${
          saveAndNew ? "/task/add" : ""
        }`;
      } else if (renderFrom === "tickets") {
        if (
          location?.pathname?.includes("list/task/edit") ||
          location?.pathname?.includes("list/task/clone")
        ) {
          navigatePath = `/tickets/list`;
        } else {
          navigatePath = `/tickets/list/overview/${overviewId}${
            saveAndNew ? "/task/add" : ""
          }`;
        }
      } else {
        if (location.pathname.includes("list/overview")) {
          navigatePath = `/tasks/list/overview/${overviewId}`;
        } else if (location.pathname.includes("tasks/dashboard")) {
          navigatePath = `/tasks/dashboard`;
          delete commonSearchParams.page;
          delete commonSearchParams.tab;
        } else {
          navigatePath = `/tasks/list${saveAndNew ? "/add" : ""}`;
        }
      }

      const queryParamsTemp = createSearchParams(
        removeEmptyFields(commonSearchParams)
      );
      const navigateUrl = `${navigatePath}?${queryParamsTemp}${
        saveAndNew && projectId ? `&project=${projectId}` : ""
      }`;

      navigate(navigateUrl);
    }
  };
  const handleSubmit = (e, saveAndNew) => {
    e.preventDefault();
    if (onSaveNew || onSave) return;
    setFormError({});
    saveAndNew ? setOnSaveNew(true) : setOnSave(true);
    const formDataWithoutRefkeys = removeRefData({
      ...formData,
      ...(renderFrom === "project" && {
        project_id: !openFrom ? itemInfoId : formData.project_id,
      }),
      ...(renderFrom === "sprints" && {
        sprint_id: !openFrom ? itemInfoId : formData.sprint_id,
        project_id: !openFrom && projectId ? projectId : formData.project_id,
      }),
      description: formData?.description?.replace("<p><br></p>", ""),
      coordinator:
        formData?.coordinator.length === 0 ? "" : formData?.coordinator,
    });
    axios({
      method: !openFrom && itemId && !isClone ? "put" : "post",
      url: `crm/task${itemId && !isClone && !openFrom ? "/" + itemId : ""}`,
      data: removeEmptyFields(formDataWithoutRefkeys),
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          handleNavigation(saveAndNew);
          if (!openFrom && itemId && !isClone) {
            addIitemToStore("updateTask", response?.data?.data);
          } else {
            addIitemToStore("newTask", response?.data?.data);
          }
          if (saveAndNew) {
            setFormData((prev) => ({
              ...prev,
              title: tasksFormKeys.title,
              description: tasksFormKeys.description,
            }));
          }
          // let currentPageNumber = !itemId ? 1 : pageNumber;
          if (openFrom === "quick_link") {
            addIitemToStore(
              "triggerFromQuickLinkTask",
              !triggerFromQuickLinkTask
            );
          } else {
            // if (tab !== "tickets") {
            //   getData(currentPageNumber, false);
            // }
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message) {
          const validation_error = error?.response?.data?.data?.errors
            ? error?.response?.data?.data?.errors
            : null;
          validation_error && setFormError({ ...validation_error });

          if ("task_id" in validation_error) {
            showToast(validation_error?.task_id[0], "error");
          } else if ("task_update_permission" in validation_error) {
            showToast(validation_error?.task_update_permission[0], "error");
          } else {
            showToast(error?.response?.data?.message, "error");
          }
        } else {
          if (error?.response?.status === 413) {
            showToast(
              "The task description size is excessive. The task cannot be created."
            );
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
        }
      })
      .finally(() => {
        saveAndNew ? setOnSaveNew(false) : setOnSave(false);
      });
  };

  const getCoordinator = async (project_id) => {
    const data = await apiCall({
      url: `crm/project/${project_id}/coordinator`,
    });
    setFormData((prev) => ({
      ...prev,
      coordinator: data[0]?.id ? data[0]?.id : "",
      coordinator_ref: {
        value: data[0]?.id ? data[0]?.id : "",
        label: data[0]?.full_name ? data[0]?.full_name : "",
      },
    }));
  };

  useEffect(() => {
    if (!itemId) {
      const id = formData.project_id
        ? formData.project_id
        : !openFrom && projectId
        ? projectId
        : !openFrom && itemInfoId;

      if (id) {
        getCoordinator(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.project_id, projectId, itemInfoId]);

  useEffect(() => {
    if (!isInitialLoad && formData?.project_id) {
      getCoordinator(formData?.project_id);
    } else if (formData?.project_id) {
      setIsInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.project_id]);

  const handleGenerativeAi = () => {
    setShowGenerativeAi(true);
  };

  const handleApplyDescription = (generativeAiDescription) => {
    setFormData((prevData) => ({
      ...prevData,
      description: generativeAiDescription,
    }));
    setShowGenerativeAi(false);
  };

  return (
    <Offcanvas
      show={show}
      placement="end"
      style={{
        width: window.innerWidth < 772 ? "100%" : "50%",
      }}
      {...{
        name: "Enable body scrolling",
        scroll: true,
        backdrop: true,
      }}
    >
      <Offcanvas.Header className="border-bottom d-flex flex-column align-items-start">
        <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            <span>
              <BiTask size={20} />
            </span>
            <span className="ms-2 mt-1" style={{ fontSize: "16px" }}>
              {Translate(
                !openFrom && itemId
                  ? isClone
                    ? "CLONE TASK"
                    : "UPDATE TASK"
                  : "CREATE TASK"
              )}
            </span>
          </div>
          <CustomBackButton
            variant="falcon-default"
            title="Close"
            onClick={() => handleNavigation()}
          />
        </Offcanvas.Title>
      </Offcanvas.Header>
      {formData?.sprint_id_ref?.label && renderFrom !== "sprints" && (
        <div
          style={{ backgroundColor: "#D3E3FD" }}
          className="my-0 px-3 d-flex align-items-center pb-1"
        >
          <span
            className="pt-1"
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Sprint : {formData?.sprint_id_ref?.label}
          </span>{" "}
        </div>
      )}
      <>
        {dataProcessing ? (
          <ListLoading />
        ) : (
          <Offcanvas.Body>
            <Form
              style={{ minHeight: "100%" }}
              id="task-form"
              onFocus={() => setFormError({})}
              className="h-100 d-flex flex-column"
            >
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label className="require-data">
                      {Translate("Title")}
                    </Form.Label>
                    <Form.Control
                      style={{ padding: "0.3125rem 8px" }}
                      as="textarea"
                      rows={2}
                      name="title"
                      onChange={handleFieldChange}
                      value={formData.title}
                      isInvalid={!!formError.title}
                      onClick={() => setFormError({})}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.title}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  {(!withoutProject || openFrom === "quick_link") && (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="require-data">
                        {Translate("Project")}
                      </Form.Label>
                      <SelectProject
                        name="project_id"
                        value={formData.project_id_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError.project_id}
                        clientId={!openFrom ? (client_id ? client_id : "") : ""}
                        clearable={false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError.project_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>{Translate("Coordinator")}</Form.Label>
                    <SelectUsers
                      name="coordinator"
                      value={formData.coordinator_ref}
                      handleFieldChange={handleFieldChange}
                      error={formError.coordinator}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.assigned_to}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{Translate("Assignee")} </Form.Label>
                    <SelectUsers
                      name="assigned_to"
                      value={formData.assigned_to_ref}
                      handleFieldChange={handleFieldChange}
                      error={formError.assigned_to}
                      disabled={!updateTaskAssigneePermission}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.assigned_to}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>{Translate("Collaborator")}</Form.Label>
                    <MultiSelectUsers
                      name="collaborators"
                      handleFieldChange={handleFieldChange}
                      error={!!formError.collaborators}
                      value={formData.collaborators_ref}
                      excludeUser={formData.assigned_to}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.collaborators}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{Translate("Label")}</Form.Label>
                    <MutiSelectTaskLabel
                      name="labels"
                      handleFieldChange={handleFieldChange}
                      error={!!formError.label}
                      value={formData.labels_ref}
                      disabled={!updateTaskLabelPermission}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.label}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} sm={12}>
                  <Row className="gx-2">
                    <Col lg={6} md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="require-data">
                          {Translate("Status")}
                        </Form.Label>
                        <SelectTaskStatus
                          value={formData.status_ref}
                          name="status"
                          handleFieldChange={handleFieldChange}
                          error={!!formError.status}
                          disabled={!updateTaskStatusPermission}
                          clearable={false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.status}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>{Translate("Priority")}</Form.Label>
                        <SelectPriority
                          name="priority"
                          value={formData.priority_ref}
                          handleFieldChange={handleFieldChange}
                          error={formError.priority}
                          fetchUrl="crm/task-priority"
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.priority}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      {Translate("Start Date")}
                    </Form.Label>
                    <AppTimePicker
                      name="start_date"
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      hourPlaceholder="hh"
                      minutePlaceholder="mm"
                      onChange={handleFieldChange}
                      value={formData.start_date}
                      isInvalid={!!formError.start_date}
                      showDefaultDate={true}
                      isClearable={true}
                    />
                    <Form.Control.Feedback type="is-invalid">
                      {formError.start_date}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      {Translate("End Date")}
                    </Form.Label>
                    <AppTimePicker
                      name="end_date"
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      hourPlaceholder="hh"
                      minutePlaceholder="mm"
                      onChange={handleFieldChange}
                      value={formData.end_date}
                      isInvalid={!!formError.end_date}
                      showDefaultDate={true}
                      isClearable={true}
                    />
                    <Form.Control.Feedback type="is-invalid">
                      {formError.end_date}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {renderFrom !== "sprints" && (
                    <Form.Group className="mb-3">
                      <Form.Label>{Translate("Sprint")}</Form.Label>
                      <SelectSprint
                        value={formData.sprint_id_ref}
                        name="sprint_id"
                        handleFieldChange={handleFieldChange}
                        error={!!formError.sprint_id}
                        projectId={
                          !openFrom && itemInfoId
                            ? itemInfoId
                            : formData.project_id
                            ? formData.project_id
                            : ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError.sprint_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>{Translate("Products")}</Form.Label>
                    <SelectProduct
                      value={formData?.product_id_ref}
                      name="product_id"
                      error={formError?.product_id}
                      handleFieldChange={handleFieldChange}
                      project_id={
                        !openFrom && projectId
                          ? projectId
                          : !openFrom && itemInfoId
                          ? itemInfoId
                          : formData.project_id
                          ? formData.project_id
                          : ""
                      }
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError.product_id}</span>
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>
                      {Translate("Description")}
                      <Button
                        size={"sm"}
                        onClick={handleGenerativeAi}
                        className="ms-1 px-2"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <BsStars
                          size={12}
                          className="mb-1"
                          color="#FFFFFF"
                          onClick={handleGenerativeAi}
                        />{" "}
                        {Translate("Generate with AI")}
                      </Button>
                    </Form.Label>
                    <div className="text-dark">
                      <TextEditor
                        name="description"
                        handleFieldChange={handleFieldChange}
                        value={formData.description}
                        isInvalid={!!formError.description}
                      />
                    </div>
                    <Form.Control.Feedback type="is-invalid">
                      {formError.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex gap-1 mt-auto pb-3">
                <SaveButton
                  handleSubmit={handleSubmit}
                  onSave={onSave}
                  id={!openFrom && itemId && !isClone}
                />
                {(!itemId || openFrom) && (
                  <SaveNewButton
                    handleSubmit={(e) => handleSubmit(e, true)}
                    onSave={onSaveNew}
                  />
                )}
                <Button
                  variant={"danger"}
                  size={"sm"}
                  onClick={() => handleNavigation()}
                >
                  {Translate("Cancel")}
                </Button>
              </div>
            </Form>
          </Offcanvas.Body>
        )}
      </>
      {showGenerativeAi && (
        <CommonAiGeneratorForm
          show={showGenerativeAi}
          handleClose={() => {
            setShowGenerativeAi(false);
          }}
          searchValue={formData?.title}
          handleApply={handleApplyDescription}
        />
      )}
    </Offcanvas>
  );
}

export default TaskForm;
