import React, { useContext, useEffect, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import TicketOverviewHeader from "./TicketOverviewHeader";
import TicketOverViewConversation from "./TicketOverViewConversation";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { apiCall } from "helpers/apiCalls";
import { DeleteContext, StoreContext } from "context/Context";
import { showToast } from "module/common/Toast/toast";
import axios from "axios";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { TbStatusChange } from "react-icons/tb";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import {
  FaMoneyBillWave,
  FaUser,
  FaUserGraduate,
  FaUserTie,
} from "react-icons/fa";
import { MdOutlinePriorityHigh } from "react-icons/md";
import { IoEarth, IoTicketOutline } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import { stringLimiter } from "helpers/utils";

const TicketOverView = () => {
  const { overviewId } = useParams();
  const [overviewData, setOverviewData] = useState({});
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(DeleteContext);
  const navigate = useNavigate();
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const permission = {
    updateStatus: GetAcodaxPermission("TICKET_STATUS", "update"),
    showProject: GetAcodaxPermission("PROJECT", "show"),
    showCustomer: GetAcodaxPermission("CUSTOMER", "show"),
    update: GetAcodaxPermission("TICKET", "update"),
  };
  const [isLoading, setIsLoading] = useState(false);
  const [ticketStatus, setTicketStatus] = useState([]);
  const [showRenderedContent, setShowRenderedContent] = useState(false);
  const { store, addIitemToStore } = useContext(StoreContext);

  useEffect(() => {
    if (overviewId) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewId]);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await apiCall({
        url: `/crm/ticket/${overviewId}`,
      });
      setOverviewData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = () => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: overviewId,
        url: "/crm/ticket/" + overviewId,
        title: "Delete Ticket",
        message: "Are you sure you want to delete this Ticket?",
        onSuccess: () => {
          navigate("/tickets/list?tab=all");
          // reset deleteContext
          dispatch({
            type: "RESET",
          });
        },
      },
    });
  };

  const handleTicketStatus = (status) => {
    if (loadingStatusUpdate) return;
    if (
      status.value &&
      status.value !== overviewId.status &&
      permission?.updateStatus
    ) {
      setLoadingStatusUpdate(true);
      axios({
        method: "post",
        url: `crm/ticket-status/update`,
        params: {
          ticket_id: overviewId?.id ? overviewId?.id : overviewId,
          status: status.value,
        },
      }).then((response) => {
        if (response.data.success === true) {
          setOverviewData(response?.data?.data);
          showToast(response.data.message, "success");
          setLoadingStatusUpdate(false);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
          setLoadingStatusUpdate(false);
        }
      });
    }
  };

  const getTicketStatusData = async () => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/ticket-status",
    });
    addIitemToStore("ticketStatus", data);
    setTicketStatus(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (store?.ticketStatus?.length === 0) {
      getTicketStatusData();
    } else {
      setTicketStatus(store?.ticketStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedStatus = ticketStatus?.map((item) => ({
    label:
      item === "awaiting_business_input"
        ? "Await"
        : item === "with_client_review"
        ? "Review"
        : item,
    value: item,
  }));

  const dataToRibbonButton = {
    id: overviewId,
    getData: getTicketStatusData,
    handleSelect: handleTicketStatus,
    ribbonData: formattedStatus,
    isLoading: isLoading,
    itemRef: {
      value: overviewData?.status,
      label:
        overviewData?.status === "awaiting_business_input"
          ? "Await"
          : overviewData?.status === "with_client_review"
          ? "Review"
          : overviewData?.status,
    },
    IconComponent: TbStatusChange,
  };

  useEffect(() => {
    if (Object.keys(overviewData).length > 0) setShowRenderedContent(true);
    return () => setShowRenderedContent(false);
  }, [overviewData]);

  const columnData = [
    {
      icon: <BiSupport size={18} color="#7A1CAC" />,
      title: "Ticket Reference:",
      value: overviewData?.reference && (
        <Badge bg="info" style={{ fontSize: "11px" }}>
          {overviewData?.reference}
        </Badge>
      ),
    },
    {
      icon: <AiOutlineCodeSandbox size={18} color="#3FA2F6" />,
      title: "Project:",
      value: stringLimiter(overviewData?.project, 55),
      id: permission?.showProject && overviewData?.project_id,
    },
    {
      icon: <FaUserTie size={14} color="#FFA38F" />,
      title: "Client:",
      value: overviewData?.customer_name,
      id: permission?.showCustomer && overviewData?.customer_id,
    },
    {
      icon: <FaUserGraduate size={16} color="#FFB22C" />,
      title: "Assignee:",
      value: overviewData?.assignee,
      id: overviewData?.assigned_to,
      ticketId: overviewData?.id,
      edit: permission?.update ? true : false,
      image: overviewData?.assignee_image,
    },
    {
      icon: <FaMoneyBillWave size={18} color="#FB8B24" />,
      title: "Billable Status:",
      value: overviewData?.billable === 1 ? "Billable" : "Non Billable",
    },
    {
      icon: <IoTicketOutline size={18} color="#508D4E" />,
      title: "Ticket Type:",
      value: overviewData?.ticket_type,
    },
    {
      icon: <MdOutlinePriorityHigh size={16} color="#433D8B" />,
      title: "Priority:",
      value: overviewData?.priority,
    },
    {
      icon: <IoEarth size={18} color="#88D66C" />,
      title: "Ticket Origin:",
      value: overviewData?.ticket_origin,
    },
    {
      icon: <FaUser size={18} color="#E4003A" />,
      title: "Created user:",
      value: overviewData?.created_user_full_name,
      id: overviewData?.created_by,
    },
  ];

  return (
    <>
      <Outlet context={[getData]} />
      {loading ? (
        <LoadingScreenCover>
          <LoadingCommon loadingText="Loading Ticket details" />
        </LoadingScreenCover>
      ) : (
        <>
          {showRenderedContent && (
            <Row className="g-3">
              <Col className="d-flex flex-column">
                <TicketOverviewHeader
                  handleDelete={handleDelete}
                  overviewData={overviewData}
                  dataToRibbonButton={dataToRibbonButton}
                  getData={getData}
                />
                <TicketOverViewConversation
                  data={overviewData}
                  columnData={columnData}
                  getData={getData}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default TicketOverView;
