import { React, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Form, Row, Col, Modal, Figure } from "react-bootstrap";
import { apiCall } from "helpers/apiCalls";
import { showToast } from "module/common/Toast/toast";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { projectFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import BackButton from "components/common/BackButton";
import SelectProjectStatus from "components/form/SelectProjectStatus";
import SelectCustomer from "components/form/SelectCustomer";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import TextEditor from "module/common/TextEditor/TextEditor";
import ListLoading from "module/common/ListLoading";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import SelectUsers from "components/form/SelectUsers";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import MultiSelectCheckBoxProduct from "components/form/MultiSelectCheckBoxProduct";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const ProjectForm = ({ show = true, handleCloseForm, isFrom = "" }) => {
  const Translate = useAxisproTranslate();
  const [getData] = useOutletContext() || [{}];
  let navigate = useNavigate();
  const { itemId, client_id, empId } = useParams();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(projectFormKeys);
  const [formError, setFormError] = useState({});
  const [logoImg, setLogoImg] = useState("");
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");

  const locationPathName = useLocation();
  const mode =
    locationPathName?.pathname?.includes("project/add") ||
    locationPathName?.pathname?.includes("project/edit");
  const renderFrom = locationPathName.pathname.split("/")[1];
  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (
        action.action === "select-option" ||
        action.action === "remove-value"
      ) {
        value = e.code ? e.code : e.value;
        if (action.name === "products") {
          const labelArray = e.map((item) => item.value);
          newFormData = {
            ...formData,
            [action.name]: labelArray,
            [action.name + "_ref"]: e,
          };
        } else {
          newFormData = {
            ...formData,
            [action.name]: value,
            [action.name + "_ref"]: e,
          };
        }
      } else if (action.action === "clear") {
        if (action.name === "products") {
          newFormData = {
            ...formData,
            [action.name]: [],
            [action.name + "_ref"]: [],
          };
        } else {
          newFormData = {
            ...formData,
            [action.name]: "",
            [action.name + "_ref"]: {
              label: "",
              value: "",
            },
          };
        }
      } else if (action.action === "text-editor") {
        value = action.value;
        newFormData = {
          ...formData,
          [action.name]: value,
        };
      }
    }
    setFormData(newFormData);
  };

  const handleFiles = (e) => {
    if (e.target.name === "project_logo") {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setLogoImg(reader.result);
        };
        reader.readAsDataURL(file);
        setFormData((prev) => ({
          ...prev,
          project_logo: file,
        }));
      } else {
        setLogoImg("");
        setFormData((prev) => ({
          ...prev,
          project_logo: null,
        }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSave) return;
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(
      {
        ...formData,
        ...(renderFrom === "account" && {
          customer_id: client_id ? client_id : formData.customer_id,
        }),
      },
      "object"
    );
    axios({
      method: "post",
      url: `crm/project${itemId ? "/" + itemId : ""}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          setFormData(projectFormKeys);
          showToast(response.data.message, "success");
          if (isFrom === "dashboard") {
            navigate(`/project/info/${response?.data?.data?.id}?tab=overview`);
          } else {
            navigate(itemId || client_id || empId ? -1 : "/project");
          }
          if (getData && typeof getData === "function") {
            (!filterItems || itemId) && getData(pageNumber ?? 1);
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    if (itemId) {
      getDataItem();
    }
    return () => {
      setOnSave(false);
      setFormData(projectFormKeys);
      setFormError({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const getDataItem = async (page = 1) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/project/" + itemId,
    });

    setFormData({
      _method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      title: data.title,
      customer_id: data.customer_id,
      customer_id_ref: {
        label: data.customer.name,
        value: data.customer_id,
      },
      start_date: data.start_date,
      end_date: data.end_date,
      price: data.price,
      status: data.status,
      status_ref: {
        label: data.status,
        value: data.status,
      },
      description: data.description,
      coordinator: data.coordinator_id,
      coordinator_ref: {
        label: data.coordinator_name,
        value: data.coordinator_id,
      },
      products: data?.products?.map((product) => product.id),
      products_ref: data?.products?.map((product) => ({
        label: product.name,
        value: product.id,
      })),
    });
    if (data?.logo) {
      setLogoImg(data?.logo);
    }
    setLoading(false);
  };

  const handleClose = () => {
    if (handleCloseForm && typeof handleCloseForm === "function") {
      handleCloseForm();
    } else {
      navigate(-1);
    }
  };

  return (
    <Modal
      show={show}
      size="lg"
      backdrop="static"
      className="no-border-radius-modal"
    >
      <Modal.Header className="">
        <div className="d-flex align-items-center">
          <AiOutlineCodeSandbox size={23} className="me-1 text-dark" />
          <span className="custom-module-header" style={{ fontSize: "16px" }}>
            {Translate(`${!itemId ? "NEW" : "UPDATE"} PROJECT`)}{" "}
          </span>
        </div>
        <CustomBackButton
          variant="falcon-default"
          title="Close"
          onClick={() => handleClose()}
        />
      </Modal.Header>

      <Modal.Body>
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="require-data">
                    {Translate("Title")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    onChange={handleFieldChange}
                    value={formData.title}
                    placeholder="Enter Name"
                    isInvalid={!!formError.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label className="require-data">
                    {Translate("Start Date")}
                  </Form.Label>
                  <AppDatePicker
                    name="start_date"
                    yearPlaceholder="yyyy"
                    monthPlaceholder="mm"
                    dayPlaceholder="dd"
                    onChange={handleFieldChange}
                    value={formData.start_date}
                    isInvalid={!!formError.start_date}
                    clearable={false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.start_date}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput5"
                >
                  <Form.Label className="require-data">
                    {Translate("Price")}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    onChange={handleFieldChange}
                    value={formData.price}
                    placeholder="Enter Name"
                    isInvalid={!!formError.price}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.price}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput6"
                >
                  <Form.Label className="require-data">
                    {Translate("Status")}
                  </Form.Label>
                  <SelectProjectStatus
                    value={formData.status_ref}
                    name="status"
                    handleFieldChange={handleFieldChange}
                    error={!!formError.status}
                    clearable={false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.status}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput4"
                >
                  <Form.Label className="require-data">
                    {Translate("End Date")}
                  </Form.Label>
                  <AppDatePicker
                    name="end_date"
                    yearPlaceholder="yyyy"
                    monthPlaceholder="mm"
                    dayPlaceholder="dd"
                    onChange={handleFieldChange}
                    value={formData.end_date}
                    isInvalid={!!formError.end_date}
                    clearable={false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.end_date}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput9"
                >
                  <Form.Label>{Translate("Products")}</Form.Label>
                  <Col>
                    <MultiSelectCheckBoxProduct
                      name="products"
                      value={formData.products_ref}
                      handleFieldChange={handleFieldChange}
                      error={formError.products}
                    />
                  </Col>
                  <Form.Control.Feedback type="invalid">
                    {formError.products}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                {renderFrom !== "account" && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label className="require-data">
                      {Translate("Client")}
                    </Form.Label>
                    <SelectCustomer
                      name="customer_id"
                      value={formData.customer_id_ref}
                      error={formError.customer_id}
                      handleFieldChange={handleFieldChange}
                      clearable={false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.customer_id}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput7"
                >
                  <Form.Label>{Translate("Coordinator")}</Form.Label>
                  <Col>
                    <SelectUsers
                      value={formData.coordinator_ref}
                      name="coordinator"
                      handleFieldChange={handleFieldChange}
                      error={formError.coordinator}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError.coordinator}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput8"
                >
                  <Form.Label>{Translate("Project Logo")}</Form.Label>
                  <Col>
                    <Form.Control
                      type="file"
                      name="project_logo"
                      onChange={handleFiles}
                    />
                  </Col>
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-flex p-0 m-0"
                  >
                    <span className="ms-auto">{formError.project_logo}</span>
                  </Form.Control.Feedback>
                </Form.Group>
                {logoImg && (
                  <Col className="mb-3">
                    <Figure
                      style={{
                        maxWidth: "200px",
                      }}
                      className="mb-0"
                    >
                      <Figure.Image
                        thumbnail
                        height={500}
                        width={200}
                        alt="145x190"
                        src={logoImg}
                        style={{
                          border: "1px dashed #d6d6d6",
                          height: "120px",
                          width: "300px",
                          objectFit: "contain",
                        }}
                      />
                    </Figure>
                  </Col>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>{Translate("Description")}</Form.Label>
                  <div className="text-dark">
                    <TextEditor
                      name="description"
                      handleFieldChange={handleFieldChange}
                      value={formData.description}
                      isInvalid={!!formError.description}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {formError.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="d-flex justify-content-end gap-1 mt-3">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={itemId}
              />
              {!onSave && (
                <BackButton variant={"danger"} size={"sm"}>
                  {Translate("Cancel")}
                </BackButton>
              )}
            </Form.Group>
          </Form>
        ) : (
          <ListLoading style={{ height: "52vh", overflow: "hidden" }} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ProjectForm;
