import { React, useState } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import Invitation from "module/common/Invitation/Invitation";
import { Button } from "react-bootstrap";
import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";

const SelectUsers = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
  disabled,
  onFocus,
  isClearable = true,
}) => {
  const [showInvitation, setInvitation] = useState(false);
  const handleInvite = () => setInvitation(!showInvitation);

  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: "user/users-autocomplete",
      setParams: (searchKey) => ({ name: searchKey }),
      setOptions: (option) => setOptionData(option),
      onFocus: onFocus,
    });

  const setOptionData = (option) => ({
    label: option.first_name + " " + option.last_name,
    value: keyName ? option[keyName] : option.id,
  });

  const NoOptionsMessage = () => {
    return (
      <div className="d-flex justify-content-between">
        <span>No options</span>
        <Button
          variant="transparant"
          className="p-0 custom-transperent-common-button"
          onClick={handleInvite}
          size="sm"
        >
          <span className="text-primary">Invite</span>
        </Button>
      </div>
    );
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={fetchData}
        onFocus={setDefaultResult}
        defaultOptions={defaultOptions}
        isLoading={loading}
        isClearable={isClearable}
        isDisabled={disabled}
        name={name ? name : "user"}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={handleFieldChange}
        className={`custom-select ${
          error && "form-control ps-0 py-0 is-invalid"
        }`}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            fontSize: "13px",
            borderRadius: "0px",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
              ? state.isSelected
                ? "#192E49"
                : "#e1e2ea"
              : state.isSelected
              ? "#696c96"
              : "inherit",
            "&:hover": {
              backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
            },
          }),
          control: (provided) => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style,
          }),
        }}
        noOptionsMessage={NoOptionsMessage}
      />
      <Invitation showInvitation={showInvitation} handleClose={handleInvite} />
    </>
  );
};

SelectUsers.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
};

export default SelectUsers;
