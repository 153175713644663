import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import ListLoading from "module/common/ListLoading";
import { FaCaretDown, FaCircle } from "react-icons/fa";
import { apiCall } from "helpers/apiCalls";
import getMeetingStatusWithProps from "module/common/helpers/getMeetingStatusWithProps";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import getProjectStatusWithProps from "module/common/helpers/getProjectStatusWithProps";
import { useSearchParams } from "react-router-dom";
import getSprintStatusWithProps from "module/common/helpers/getSprintStatusWithProps";
import { StoreContext } from "context/Context";
import getTicketStatusWithProps from "module/common/helpers/getTicketStatusWithProps";

function CommonStatusUpdateDropDown({
  getData,
  postParams,
  activeStatus = "",
  fetchUrl,
  method,
  postUrl,
  width = "max-content",
  setResponseData,
  setLoading,
  afterStatusChange,
  disableAfterEffects,
  isDisabled = false,
  oldStatus,
  statusChange,
}) {
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get("page");
  const { store, addIitemToStore } = useContext(StoreContext);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [status, setStatus] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const getColorFunction = (status, type, iconSize) => {
    if (fetchUrl?.includes("meeting")) {
      return getMeetingStatusWithProps(status, type, iconSize);
    } else if (fetchUrl?.includes("task")) {
      return getTaskStatusWithProps(status, type, iconSize);
    } else if (fetchUrl?.includes("project")) {
      return getProjectStatusWithProps(status, type, iconSize);
    } else if (fetchUrl?.includes("sprint")) {
      return getSprintStatusWithProps(
        status.toLowerCase().replace(" ", "_"),
        type,
        iconSize
      );
    } else if (fetchUrl?.includes("ticket")) {
      return getTicketStatusWithProps(status, type, iconSize);
    }
  };

  const getParams = (status) => ({ ...postParams, status });

  const handleToggle = () => {
    if (isDisabled) {
      showToast(
        "The task is currently in progress. Please stop the timer before changing the status.",
        "error"
      );
    } else if (!dataProcessing) {
      setIsOpen((prev) => !prev);
    }
  };

  const fetchData = async () => {
    const statusMapping = {
      task: "taskStatus",
      sprint: "sprintStatus",
      project: "projectStatus",
      meeting: "meetingStatus",
      ticket: "ticketStatus",
    };

    const type = Object.keys(statusMapping).find((key) =>
      fetchUrl?.includes(key)
    );

    if (type) {
      const storeKey = statusMapping[type];
      if (store?.[storeKey]?.length === 0) {
        setDataProcessing(true);
        const response = await apiCall({ url: fetchUrl });
        setStatus(response);
        addIitemToStore(storeKey, response);
        setDataProcessing(false);
      } else {
        setStatus(store?.[storeKey]);
      }
    } else {
      setDataProcessing(true);
      const response = await apiCall({ url: fetchUrl });
      setStatus(response);
      setDataProcessing(false);
    }
  };

  useEffect(() => {
    if (isOpen) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSubmit = (e, status) => {
    e.preventDefault();
    if (typeof afterStatusChange === "function") {
      afterStatusChange(
        status,
        postParams?.task_id
          ? postParams?.task_id
          : postParams?.sprint_id
          ? postParams?.sprint_id
          : postParams?.ticket_id
      );
      setIsOpen(false);
    }
    if (typeof statusChange === "function") {
      statusChange(status);
      setIsOpen(false);
    }
    if (!disableAfterEffects) {
      setSubmitLoading(true);
    }
    if (setLoading) setLoading(true);
    axios({
      method: method,
      url: postUrl,
      params: getParams(status),
    })
      .then((response) => {
        if (response.data.success === true) {
          if (!disableAfterEffects) showToast(response.data.message, "success");
          if (setResponseData) setResponseData(response.data.data);
          if (typeof getData === "function") getData(pageNumber);
          if (!disableAfterEffects) setIsOpen(false);
        }
      })
      .catch((error) => {
        if (typeof afterStatusChange === "function") {
          afterStatusChange(
            oldStatus,
            postParams?.task_id
              ? postParams?.task_id
              : postParams?.sprint_id
              ? postParams?.sprint_id
              : postParams?.ticket_id
          );
        }
        if (error?.response?.data?.message) {
          const validation_error = error?.response?.data?.data?.errors
            ? error?.response?.data?.data?.errors
            : null;
          if ("collaborator_working" in validation_error) {
            showToast(validation_error?.collaborator_working[0], "error");
          } else if ("task_id" in validation_error) {
            showToast(validation_error?.task_id[0], "error");
          } else if ("status" in validation_error) {
            showToast(validation_error?.status[0], "error");
          } else {
            showToast(error.response.data.message, "error");
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .finally(() => {
        setSubmitLoading(false);
        if (setLoading) setLoading(false);
      });
  };

  return (
    <>
      <Dropdown
        show={isDisabled ? false : isOpen}
        autoClose="outside"
        onToggle={handleToggle}
      >
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="transparent"
          className="custom-transperent-common-button p-0 shadow-none"
        >
          <Button
            style={{ width: width }}
            className="task-status-drop-down shadow border d-flex justify-content-center"
            variant="light"
          >
            <span className="text-capitalize" style={{ fontSize: "13px" }}>
              <span>{getColorFunction(activeStatus, "icon", 16)}</span>
              <span className="ms-2 me-1 text-capitalize">
                {activeStatus === "awaiting_business_input"
                  ? "Await"
                  : activeStatus === "with_client_review"
                  ? "Review"
                  : activeStatus.replace(/_/g, " ")}
              </span>
              <FaCaretDown className="mb-1" />
            </span>
          </Button>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end fs--1">
          {dataProcessing ? (
            <ListLoading
              style={{
                height: "170px",
                overflow: "hidden",
                minWidth: "155px",
              }}
            />
          ) : status?.length > 0 ? (
            <>
              {status
                ?.filter((item) => item !== activeStatus)
                ?.map((status, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      style={{
                        fontSize: "13px",
                        cursor: "pointer",
                        minWidth: "min-content",
                      }}
                      className="text-capitalize"
                      onClick={(e) => {
                        handleSubmit(e, status);
                      }}
                    >
                      <FaCircle
                        className="me-2 mb-1"
                        color={getColorFunction(status)}
                        size={10}
                      />
                      <span>
                        {status === "awaiting_business_input"
                          ? "Await"
                          : status === "with_client_review"
                          ? "Review"
                          : status.replace(/_/g, " ")}
                      </span>
                    </Dropdown.Item>
                  );
                })}
            </>
          ) : (
            <Dropdown.Item className="custom-select-dropdown-item inactive text-center">
              <span>No Data Found</span>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {submitLoading && !setLoading && <LoadingOverlay />}
    </>
  );
}

export default CommonStatusUpdateDropDown;
