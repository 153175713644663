import { React, useContext, useEffect, useState } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Offcanvas,
  Tab,
  Tabs,
  Badge,
} from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import axios from "axios";
import {
  MdAttachFile,
  MdInfoOutline,
  MdOutlineDateRange,
  MdOutlineTimer,
} from "react-icons/md";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import CommonAttachments from "module/common/ViewPages/Common/Attachments/CommonAttachments";
import {
  CustomBackButton,
  CustomEditButton,
  RedirectButton,
} from "module/common/Buttons/AppButtons";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import getProjectStatusWithProps from "module/common/helpers/getProjectStatusWithProps";
import getProjectFlagWithProps from "module/common/helpers/getProjectFlagWithProps";
import { PiMoney } from "react-icons/pi";
import { HiStatusOnline } from "react-icons/hi";
import { BiComment, BiTask } from "react-icons/bi";
import Comments from "module/common/ViewPages/Common/Comments/Comments";
import { IoMdPeople } from "react-icons/io";
import { FiPackage } from "react-icons/fi";
import ShowHtmlContent from "components/common/showHtmlContent/ShowHtmlContent";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import { AuthWizardContext } from "context/Context";

function ProjectOffcanvas({ show, id, onHide }) {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const navigate = useNavigate();
  const [key, setKey] = useState("info");
  const [projectData, setProjectData] = useState({});
  const [loading, setLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const location = useLocation();
  const permission = {
    update: GetAcodaxPermission("PROJECT", "update"),
  };

  const fetchData = async () => {
    setLoading(true);
    axios
      .get(`crm/project/${id}`)
      .then((res) => {
        if (res.data.success) {
          setProjectData(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnHide = () => {
    if (show) {
      onHide();
      setKey("info");
    } else {
      navigate(-1);
      setKey("info");
    }
  };

  const createProductsString = (products) => {
    return products.length !== 0
      ? products.reduce((col, product, index) => {
          return col + (index !== 0 ? ", " : "") + (product?.name || "");
        }, "")
      : "N/A";
  };

  const handleInfoRoute = () => {
    localStorage.setItem(
      "actualRoute",
      `${location.pathname}${location.search}`
    );
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleOnHide}
      placement="end"
      style={{
        width: "600px",
      }}
    >
      <Offcanvas.Body className="p-0">
        {Object.keys(projectData).length > 0 && !loading ? (
          <>
            <div>
              <Offcanvas.Header className="border-bottom">
                <Offcanvas.Title className="d-flex flex-wrap justify-content-between align-items-center gap-2 w-100">
                  <div className="d-flex align-items-center">
                    <AiOutlineCodeSandbox
                      size={24}
                      className="me-1 text-dark"
                    />
                    <span className="me-2 fs-1">
                      {Translate("Project Overview")}
                    </span>
                    <span>
                      <RedirectButton
                        title={"Project Overview"}
                        route={`/project/info/${id}?${createSearchParams({
                          ...allQueryParams,
                          tab: "overview",
                        })}`}
                        onClick={handleInfoRoute}
                      />
                    </span>
                  </div>
                  <div className="d-flex flex-wrap align-items-center gap-2">
                    {permission?.update && (
                      <CustomEditButton
                        variant="light"
                        onClick={() => {
                          navigate(
                            `/project/edit/${id}?${createSearchParams({
                              ...allQueryParams,
                            })}`
                          );
                        }}
                        label="Edit Task"
                        className="shadow"
                      />
                    )}
                    <CustomBackButton
                      variant="light"
                      title="Back"
                      onClick={handleOnHide}
                      className="shadow"
                    />
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <div
                className="p-3 border-bottom"
                style={{ backgroundColor: "#eff0fd" }}
              >
                <Card>
                  <Card.Header className="rounded-0 ticket-custom-overview-card-header pb-2">
                    <div className="d-grid">
                      <span
                        className="fw-bold text-capitalize"
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <div>
                          <span>{projectData?.title}</span>
                        </div>
                        <div className="ms-auto">
                          <Badge
                            bg={"info"}
                            className="me-1 ms-1"
                            style={{ fontSize: "11px" }}
                          >
                            {projectData?.project_code}
                          </Badge>
                          <Badge
                            bg={"none"}
                            style={{
                              backgroundColor: getProjectFlagWithProps(
                                projectData?.flag
                              ),
                              fontSize: "11px",
                            }}
                          >
                            {projectData?.flag}
                          </Badge>
                        </div>
                      </span>
                      {projectData?.description && (
                        <span className="pt-2">
                          <ShowHtmlContent
                            description={projectData?.description}
                          />
                        </span>
                      )}
                      <div className="d-flex ms-auto mt-3">
                        <div className="d-flex align-items-center me-2">
                          <MdOutlineDateRange
                            size={15}
                            color="#508D4E"
                            className="me-1"
                            style={{ minWidth: "fit-content" }}
                          />
                          {Translate("Start Date")} :{" "}
                          <span className="fw-bold ms-1">
                            {projectData?.start_date_formated}{" "}
                          </span>
                        </div>
                        --
                        <div className="d-flex align-items-center ms-2">
                          <MdOutlineDateRange
                            size={15}
                            color="#E4003A"
                            className="me-1"
                            style={{ minWidth: "fit-content" }}
                          />
                          {Translate("End Date")} :{" "}
                          <span className="fw-bold ms-1">
                            {projectData?.end_date_formated}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="border rounded-0 p-0">
                    <ListGroup key="md" horizontal="md" className="d-flex">
                      {user?.customer_id ? (
                        ""
                      ) : (
                        <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                          <div className="d-flex mb-2">
                            <FaUser
                              size={12}
                              color="#3FA2F6"
                              className="mt-1"
                              style={{ minWidth: "fit-content" }}
                            />
                            <div className="d-flex flex-column ms-1">
                              <span className="custom-text-muted-crm fw-bold">
                                {Translate("Account")}
                              </span>
                              <span className="fw-bold text-capitalize text-dark">
                                {projectData?.customer?.name ?? ""}
                              </span>
                            </div>
                          </div>
                        </ListGroup.Item>
                      )}
                      <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                        <div className="d-flex mb-2">
                          <PiMoney
                            size={17}
                            color="#FFA823"
                            style={{ marginTop: 1, minWidth: "fit-content" }}
                          />
                          <div className="d-flex flex-column ms-1">
                            <span className="custom-text-muted-crm fw-bold">
                              {Translate("Price")}
                            </span>
                            <span
                              className="fw-bold text-capitalize text-dark"
                              style={{ width: "max-content" }}
                            >
                              {projectData?.price_formatted ?? ""}
                            </span>
                          </div>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                        <div className="d-flex mb-2">
                          <HiStatusOnline
                            size={16}
                            color="#433D8B"
                            style={{ minWidth: "fit-content" }}
                          />
                          <div className="d-flex flex-column ms-1">
                            <span className="custom-text-muted-crm fw-bold">
                              {Translate("Status")}
                            </span>
                            <div
                              className={`d-flex border p-0 px-3 border-${getProjectStatusWithProps(
                                projectData?.status
                              )}`}
                            >
                              <span
                                className="me-1"
                                style={{ minWidth: "fit-content" }}
                              >
                                {getProjectStatusWithProps(
                                  projectData?.status,
                                  "icon",
                                  12
                                )}
                              </span>
                              <span
                                style={{ fontSize: "10px" }}
                                className="fw-bold text-dark text-capitalize"
                              >
                                {projectData?.status}
                              </span>
                            </div>
                          </div>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <Tabs
              defaultActiveKey="info"
              className="custom-lead-offcanvas-header-tabs mt-2"
              variant="tabs"
              justify
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab
                eventKey="info"
                title={
                  <div className="d-flex align-items-center">
                    <MdInfoOutline size={15} color="#3FA2F6" />
                    <span className="ms-1">{Translate("Info")}</span>
                  </div>
                }
              >
                <Card className="card-user-profile">
                  <Card.Body>
                    <div className="">
                      <ListGroup className="border rounded-0">
                        <ListGroupItem
                          style={{ color: "black" }}
                          className="p-3 p-3 pt-2 pb-2 rounded-0 overview-details-title"
                        >
                          <AiOutlineCodeSandbox
                            size={16}
                            className="mb-1 me-1"
                            style={{ minWidth: "fit-content" }}
                          />
                          {Translate("PROJECT INFORMATION")}
                        </ListGroupItem>
                        <div className="p-2 ps-3 pe-3">
                          <ListGroupItem
                            style={{ color: "black" }}
                            className="border-0 ps-0 pt-1 pb-2"
                          >
                            <FiPackage
                              size={16}
                              className="me-1"
                              color="#3FA2F6"
                              style={{ minWidth: "fit-content" }}
                            />
                            {Translate("Products")} :{" "}
                            <span className="text-dark fw-bold">
                              {createProductsString(projectData?.products)}
                            </span>
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ color: "black" }}
                            className="border-0 ps-0 pb-2 pt-1 "
                          >
                            <IoMdPeople
                              size={18}
                              className="me-1"
                              color="#508D4E"
                              style={{ minWidth: "fit-content" }}
                            />
                            {Translate("Total Members")} :{" "}
                            <span className="text-dark fw-bold">
                              {projectData.total_members
                                ? projectData.total_members
                                : "N/A"}
                            </span>
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ color: "black" }}
                            className="border-0 ps-0 pt-1 pb-2"
                          >
                            <BiTask
                              size={16}
                              className="me-1"
                              color="#433D8B"
                              style={{ minWidth: "fit-content" }}
                            />
                            {Translate("Total Tasks")} :{" "}
                            <span className="text-dark fw-bold">
                              {projectData.total_tasks
                                ? projectData.total_tasks
                                : "N/A"}
                            </span>
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ color: "black" }}
                            className="border-0 ps-0 pt-1 pb-2"
                          >
                            <MdOutlineTimer
                              size={18}
                              className="me-1"
                              color="#88D66C"
                              style={{ minWidth: "fit-content" }}
                            />
                            {Translate("Total Working Hours")} :{" "}
                            <span className="text-dark fw-bold">
                              {projectData.total_working_hr
                                ? projectData.total_working_hr
                                : "N/A"}
                            </span>
                          </ListGroupItem>
                        </div>
                      </ListGroup>
                    </div>
                  </Card.Body>
                  <Card.Footer className="pt-0 pb-3 px-4">
                    <div className="d-flex mt-2 justify-content-between">
                      <div className="d-flex custom-text-muted">
                        <FaUser
                          size={14}
                          color="#3FA2F6"
                          style={{ marginTop: 2, minWidth: "fit-content" }}
                        />
                        <div className="d-flex ms-1">
                          <span className="me-1">
                            {Translate("Created By")} :
                          </span>
                          <span className="ms-1  fw-bold">
                            {projectData?.created_user_full_name
                              ? projectData.created_user_full_name
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex custom-text-muted">
                        <MdOutlineDateRange
                          size={17}
                          color="#FFA38F"
                          style={{ marginTop: 1, minWidth: "fit-content" }}
                        />
                        <div className="ms-1">
                          <span className="">{Translate("Created at")} :</span>
                          <span className="ms-1 fw-bold">
                            {projectData?.created_at
                              ? projectData.created_at
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card.Footer>
                </Card>
              </Tab>
              <Tab
                eventKey="comments"
                title={
                  <div className="d-flex align-items-center">
                    <BiComment size={14} color="#B692C2" />
                    <span className="ms-1">{Translate("Comments")}</span>
                  </div>
                }
                className="custom-empl-header-tab"
              >
                {key === "comments" && (
                  <div className="p-3 pt-1">
                    <Comments id={id ? id : ""} comment_type="project" />
                  </div>
                )}
              </Tab>
              <Tab
                eventKey="attachment"
                title={
                  <div className="d-flex align-items-center">
                    <MdAttachFile size={14} color="#3FA2F6" />{" "}
                    <span className="ms-1">{Translate("Attachments")}</span>
                  </div>
                }
              >
                {key === "attachment" && (
                  <CommonAttachments type="PROJECT" itemId={id ? id : ""} />
                )}
              </Tab>
            </Tabs>
          </>
        ) : loading ? (
          <div className="d-flex h-100 justify-content-center align-items-center">
            <LoadingScreen message="Fetching Data..." />
          </div>
        ) : (
          <div className="d-flex h-100 justify-content-center align-items-center">
            <span>No Data Available</span>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default ProjectOffcanvas;
